<template>
  <div class="veiculos">
    <div>
      <div v-if="carregando" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <Topo :decoded="decoded" @OpenMenuLateral="menuOpen = true"></Topo>
      <div class="container p-3 mb-5">
        <b-row>
          <b-col cols="1">
            <router-link class="link-black" tag="a" :to="'/dashboard'">
              <i class="fa fa-chevron-left text-success"></i>
            </router-link>
          </b-col>

          <b-col>
            <h5 class="m-4 text-center">Veículos</h5>
          </b-col>
        </b-row>
        <div
          v-if="!veiculos || (veiculos.length === 0 && !carregando)"
          class="alert alert-danger"
        >
          Nenhum Veículo Encontrada
        </div>

        <swipe-list
          v-else
          ref="list"
          class="card"
          :disabled="!enabled"
          :items="veiculos"
          item-key="id"
          :revealed.sync="revealed"
          :item-disabled="({ disabled }) => disabled"
        >
          <template v-slot="{ item }">
            <div
              ref="content"
              class="card-content"
              :class="`list-group-item-${buscaClasseVeiculo(item.situacao)}`"
            >
              <b-row>
                <b-col>
                  {{ item.descricao_modelo }}
                  <b-badge variant="primary">{{ item.placa }}</b-badge>
                  <b-badge :variant="buscaClasseVeiculo(item.situacao)">{{
                    item.situacao
                  }}</b-badge>
                </b-col>
                <b-col
                  v-if="item.situacao !== 'INATIVO' && !item.cancelamento"
                  cols="1"
                >
                  <font-awesome-icon icon="chevron-left" />
                </b-col>
              </b-row>
              <b-row
                v-if="item.situacao !== 'INATIVO' && item.cancelamento"
                class="mt-2"
              >
                <b-col>
                  <div class="alert alert-warning">
                    Veículo possui uma solicitação de cancelamento em aberto
                    (protocolo
                    {{ item.cancelamento }})
                  </div>
                </b-col>
              </b-row>

              <b-row
                v-if="
                  item.situacao !== 'INATIVO' &&
                  !verificaEmpresaBp(String(decoded.id_empresa))
                "
                class="mt-2"
              >
                <b-col>
                  <b-form-group
                    v-if="item.solicitado"
                    id="input-group-2"
                    label="Motivo"
                    label-for="input-2"
                  >
                    <hr />
                    Abrir Solicitação de Cancelamento
                    <b-select
                      :options="motivos"
                      class="mb-2"
                      name="Motivo"
                      id="Motivo"
                      v-model="formCancelamento.motivo"
                      v-validate="{ required: true }"
                      :state="validateState('Motivo')"
                      :class="validateClass('Motivo')"
                    ></b-select>
                    <button
                      @click="abrirChamado(item)"
                      class="btn btn-warning btn-block"
                      type="button"
                      id="button-addon2"
                    >
                      Abrir Solicitação
                    </button>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </template>
          <template v-slot:right="{ item }">
            <div
              v-if="utils.verificaEmpresaBemProtege(decoded.id_empresa)"
              class="swipeout-action orange"
            >
              <a
                target="_blank"
                class="text-white"
                :href="`${linkRelativo}/contratoAdesao/${item.chave}`"
              >
                <i class="fa fa-file-o"></i>
              </a>
            </div>
            <div
              v-if="utils.verificaEmpresaBemProtege(decoded.id_empresa)"
              class="swipeout-action black"
            >
              <a
                target="_blank"
                class="text-white"
                :href="`${linkRelativo}/laudoVistoria/${item.chave}`"
              >
                <i class="fa fa-folder"></i>
              </a>
            </div>
            <div class="swipeout-action blue" @click="direcionaDetalhes(item)">
              <!-- place icon here or what ever you want -->
              <i class="fa fa-search"></i>
            </div>

            <div
              v-if="
                item.situacao != 'INATIVO' &&
                !item.cancelamento &&
                !verificaEmpresaBp(String(decoded.id_empresa))
              "
              class="swipeout-action red"
              @click="item.solicitado = true"
            >
              <i class="fa fa-ban"></i>
            </div>
          </template>
        </swipe-list>

        <!--b-list-group>
          <b-list-group-item
            v-for="item in veiculos"
            :key="item.id"
            :variant="buscaClasseVeiculo(item.situacao)"
          >
            {{ item.descricao_modelo }}
            <b-badge variant="primary">{{ item.placa }}</b-badge>
            <b-badge :variant="buscaClasseVeiculo(item.situacao)">{{
              item.situacao
            }}</b-badge>
            <div v-if="item.situacao == 'ATIVO'" class="mt-2">
              <button
                v-if="item.solicitado != true"
                @click="item.solicitado = true"
                class="btn btn-warning btn-block"
                type="button"
                id="button-addon2"
              >
                Solicitar Cancelamento
              </button>
              <b-form-group
                v-if="item.solicitado == true"
                id="input-group-2"
                label="Motivo"
                label-for="input-2"
              >
                <b-select
                  :options="motivos"
                  class="mb-2"
                  name="Motivo"
                  id="Motivo"
                  v-model="formCancelamento.motivo"
                  v-validate="{ required: true }"
                  :state="validateState('Motivo')"
                  :class="validateClass('Motivo')"
                ></b-select>
                <button
                  @click="abrirChamado(item)"
                  class="btn btn-success btn-block"
                  type="button"
                  id="button-addon2"
                >
                  Abrir Chamado
                </button>
              </b-form-group>decoded.id_prospect
            </div>
          </b-list-group-item>
        </b-list-group-->
      </div>
      <b-modal v-model="modal" hide-footer centered :title="`Detalhes Veículo`">
        <div v-if="Object.keys(dadosVeiculoModal).length > 0">
          {{ dadosVeiculoModal.nomeGrupo }} -
          {{ dadosVeiculoModal.veiculoMarca }}
          {{ dadosVeiculoModal.veiculoModelo }}
          {{ dadosVeiculoModal.AnoFabricacao }} /
          {{ dadosVeiculoModal.AnoModelo }}
          <br />
          Placa: {{ dadosVeiculoModal.placa }}<br />
          Chassi: {{ dadosVeiculoModal.chassi }}<br />
          Renavam: {{ dadosVeiculoModal.renavam }} <br />
          <hr />
          <h5>Produtos</h5>
          <div v-if="!dadosVeiculoModal.produtos" class="alert alert-danger">
            Nenhum Produto Encontrado
          </div>
          <b-list-group v-else>
            <b-list-group-item
              v-for="item in dadosVeiculoModal.produtos"
              :key="item.id"
              :variant="`${item.situacao == 'ATIVO' ? 'success' : 'danger'}`"
            >
              <b-row>
                <b-col>
                  {{ item.descricao_produto_sga }}
                  <b-badge variant="primary">{{
                    item.valor | currency
                  }}</b-badge>
                  <b-badge>{{ item.situacao }}</b-badge>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <hr />
          <h5>Propostas</h5>
          <div v-if="!dadosVeiculoModal.propostas" class="alert alert-danger">
            Nenhuma Proposta Encontrada
          </div>
          <b-list-group v-else>
            <b-list-group-item
              v-for="item in dadosVeiculoModal.propostas"
              :key="item.id"
              :variant="`${item.id_status == 4 ? 'success' : ''}`"
            >
              <b-row>
                <b-col>
                  {{ item.id_proposta }} - {{ item.tipoProposta }}

                  <b-badge>{{ item.nomeStatus }}</b-badge>
                  <b-button
                    target="_blank"
                    variant="outline-primary"
                    :href="`${getWindow().replace('app', 'adm')}/proposta/${
                      item.chave
                    }`"
                  >
                    <i class="fa fa-search-plus"></i>
                  </b-button>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          <hr />
          <h5>Termos (Produtos Adicionais)</h5>
          <div class="pb-5 mb-5">
            <div
              v-if="!dadosVeiculoModal.termos"
              class="alert alert-danger mb-5"
            >
              Nenhum Termo de Assinatura encontrado
            </div>
            <b-list-group v-else>
              <b-list-group-item
                v-for="item in dadosVeiculoModal.termos"
                :key="item.id"
                :variant="`${item.dataAssinatura ? 'success' : ''}`"
              >
                <b-row>
                  <b-col>
                    {{ item.id_assinatura }} - {{ item.origem }}
                    <div v-if="item.dataAssinatura">
                      Assinado em {{ formataData(item.dataAssinatura) }}
                    </div>
                    <b-badge v-else>Em aberto</b-badge>
                    <b-button
                      v-if="item.assinatura"
                      variant="outline-primary"
                      @click="exibeTermo(item)"
                    >
                      <i class="fa fa-search-plus"></i>
                    </b-button>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </b-modal>
      <b-modal v-model="modalTermo" hide-footer centered :title="titleModal2">
        <div v-html="itemModal2.termoPreenchido"></div>
        <img width="100%" :src="itemModal2.assinatura" />
      </b-modal>

      <div class="m-5">&nbsp;</div>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import Topo from './Topo';
import MenuRodape from './MenuRodape';
import ConteudoDash from './ConteudoDash';
import MenuLateral from './MenuLateral';
import service from '../services';
import utils from '../services/utils';
import pdf from 'vue-pdf';
import moment from 'moment';
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
import { getListEmpresas } from '../services/multiple-empresa';
import { decryptData } from '../services/decryptData';

export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      utils: utils,
      linkRelativo: window.location.origin.replace('app', 'adm'),
      dadosProposta: {},
      decoded: {},
      enabled: true,
      menuOpen: false,
      modal: false,
      modalTermo: false,
      titleModal2: '',
      itemModal2: {},
      veiculos: [],
      motivos: [{ text: 'Selecione', value: null }],
      formCancelamento: {},
      carregando: false,
      regulamento: null,
      revealed: {},
      dadosVeiculoModal: {},
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  components: {
    Topo,
    ConteudoDash,
    MenuRodape,
    MenuLateral,
    pdf,
    SwipeOut,
    SwipeList,
  },
  methods: {
    getWindow() {
      return window.location.origin;
    },
    exibeTermo(item) {
      this.modalTermo = true;
      this.itemModal2 = item;
      this.titleModal2 = item.origem;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    direcionaDetalhes(item) {
      this.modal = true;
      this.carregando = true;
      var objSend = {};
      objSend.idVeiculo = item.id_veiculo;
      service
        .post('Veiculos', 'detalheVeiculo', objSend)
        .then((res) => {
          var aux = res.data;
          aux.produtos = JSON.parse(aux.produtos);
          aux.propostas = JSON.parse(aux.propostas);
          aux.termos = JSON.parse(aux.termos);
          this.dadosVeiculoModal = aux;
          this.carregando = false;
        })
        .catch((e) => {
          this.carregando = false;
          this.$bvToast.toast(e, {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 3500,
          });
        });
    },
    buscaClasseVeiculo(situacao) {
      if (situacao == 'INATIVO') return 'danger';
      else if (situacao == 'ATIVO') return 'success';
      else return 'warning';
    },
    formataRegional(id) {
      return id.toString().padStart(4, '0');
    },
    formataId(id) {
      return id.toString().padStart(6, '0');
    },
    formataData(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    formataAno(data) {
      return moment(data).format('YYYY');
    },
    buscaDadosSelects(model, array, campoDescricao, campoPK) {
      service.getAll('g', {}, `${model}/list/`).then((res) => {
        res.data = decryptData(res.data);
        res.data.forEach((element) => {
          this[array].push({
            value: element[campoPK],
            text: element[campoDescricao],
          });
        });
      });
    },
    abrirChamado(item) {
      if (!this.formCancelamento.motivo) {
        this.$bvToast.toast('Selecione um Motivo', {
          title: 'Atenção!',
          solid: true,
          variant: 'danger',
          toaster: 'b-toaster-top-full',
          appendToast: false,
          autoHideDelay: 3500,
        });
        return;
      }
      this.carregando = true;
      var objSend = {};
      objSend.placa = item.placa;
      objSend.chassi = item.chassi;
      objSend.idMotivo = this.formCancelamento.motivo;
      service
        .post('Cancelamento', 'iniciaProcessoPeloCliente', objSend)
        .then((res) => {
          this.$bvToast.toast(
            'Processo de cancelamento adicionado com sucesso',
            {
              title: 'Atenção!',
              solid: true,
              variant: 'success',
              toaster: 'b-toaster-top-full',
              appendToast: false,
              autoHideDelay: 3500,
            },
          );
          this.carregando = false;
          this.buscaVeiculos();
        })
        .catch((e) => {
          this.carregando = false;
          this.$bvToast.toast(e, {
            title: 'Atenção!',
            solid: true,
            variant: 'danger',
            toaster: 'b-toaster-top-full',
            appendToast: false,
            autoHideDelay: 3500,
          });
        });
    },
    async buscaVeiculos() {
      this.carregando = true;
      this.veiculos = [];
      this.erroConexao = '';

      try {
        const res = await service.getAll('Veiculos', {}, 'listVehiclesWebApp');

        this.veiculos = res.data.vehicles.map((veiculo) => {
          return { ...veiculo, solicitado: false };
        });

        this.veiculos.sort((firstVehicle, secondVehicle) => {
          return firstVehicle.situacao < secondVehicle.situacao
            ? -1
            : firstVehicle.situacao > secondVehicle.situacao
            ? 1
            : 0;
        });
      } catch (error) {
        console.error('Erro ao buscar veiculos', error);
        this.erroConexao =
          'Seu aplicativo parece estar offline, verifique sua conexao';
      } finally {
        this.carregando = false;
      }
    },
    buscaDados() {
      this.$vs.loading();
      var objSend = {};
      objSend.idProposta = this.$route.params.idCotacao;
      objSend.qtdPorPagina = this.itemsPerPage;
      objSend.idEmpresa = this.idEmpresa;
      this.logo = localStorage.getItem('logo');
      service
        .post('Cotacao', 'listarCotacoes', objSend)
        .then((res) => {
          if (res.data.length > 0) {
            this.dadosProposta = res.data[0];
            this.implementos = res.data[0].implementos;
            this.agregados = res.data[0].agregados;
            this.diaVencimentoMensalidade =
              this.dadosProposta.diaVencimentoMensalidade;
            this.dataVencimentoAvistaAux =
              this.dadosProposta.dataVencimentoAvista;
            this.buscaFotosPorProposta();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Atenção',
              text: 'Você não tem acesso a visualização desta Proposta/Vistoria',
              color: 'danger',
            });
            this.$vs.loading.close();
          }
        })
        .catch((e) => {
          this.$vs.notify({
            position: 'top-center',
            title: 'Atenção',
            text: e,
            color: 'danger',
          });
          this.$vs.loading.close();
        });
    },
    openDecoded() {
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
      }
    },
    verificaEmpresaBp(idEmpresa) {
      const empresasBP = [4, 118, 120, 121, 123, 125, 127, 128];
      return empresasBP.includes(idEmpresa);
    },
  },
  mounted() {
    this.openDecoded();
    this.buscaVeiculos();
    this.buscaDadosSelects(
      'domMotivoCancelamento',
      'motivos',
      'nomeMotivo',
      'idMotivo',
    );
    //this.buscaDados();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
body {
  overflow-x: hidden;
  background: none !important;
}
.veiculos {
  padding-bottom: 100px;
}
h5 {
  text-transform: uppercase;
  font-family: 'robotobold';
}
.btn {
  border-radius: 10px;
}
.badge {
  font-size: 90% !important;
}
</style>
<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
</style>
